@import "variables";
@import "buttons";
@import "colors";
@import "forms";
@import "flex_box";
@import "flex_list";
@import "flex_block";
@import "flex_cards";
@import "grid";
@import "topnav/topnav";
@import "sidenav/sidenav";
@import "healpers";
//
//@import 'sidenav';

//@media (min-width: 600px) {}
//@media (min-width: 1024px) {}
//@media (min-width: 1280px) {}

::selection {
  color: #FFFFFF;
  background: $primary_color;
}

html {
  scroll-behavior: smooth;
  font-size: 100%; /* 1 rem = (16px * 50% = 8px) */
  -webkit-tap-highlight-color: transparent;
}

::selection {
  color: #FFFFFF;
  background: $primary_color;
}

body {

  font-size: .9rem;
  font-family: "Roboto", sans-serif;
  background: linear-gradient($very_light_grey 15%, #FFFFFF);
  color: $dark_color;
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: hidden;

  @media (min-width: 600px) {
    font-size: .95rem;
  }
  @media (min-width: 1024px) {}
  @media (min-width: 1280px) {
    font-size: 1.1rem;
  }
}

header {
  position: relative;
}

#main {
  position: relative;
  padding-bottom: 56px;

  @media (min-width: 600px) {
    position: relative;
    min-height: calc(100vh - 241px);

  }

  //@media (min-width: 1024px) {}

  @media (min-width: 1280px) {
    padding-bottom:96px;
  }
}

h1, h2, h3, h4, h5 {
  font-family: "Saira", sans-serif;
}

h1 {
  position: relative;
  z-index: 2;
  margin-top: 5%;
  margin-bottom: 5%;
  color: $light_color;
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 2px 2px 5px -2px rgba(0, 0, 0, .1);

  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}

}

h2 {
  position: relative;
  margin-top: 0;
  font-size: 1.75rem;
  line-height: 32px;
  text-transform: uppercase;
  font-weight: 600;
  color: $primary_color;

  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}

}

h3 {
  color: $primary_color;
  font-size: 1.50rem;
  margin: 0;
  font-weight:600;
  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}

.top-subtitle, .bottom-subtitle {
  font-size: 1.2rem;
  font-weight: 700;
  color: $secondary_color;
  text-align: left;
  margin-bottom:0;
  margin-top:0;
}

p {
  text-align: justify;
  font-size: 1rem;
  line-height: 28px;
  color: #595959;

  @media (min-width: 600px) {

  }
  @media (min-width: 1024px) {

  }
  @media (min-width: 1280px) {
    font-size: 1.05rem;
  }
}

a {
  font-size: 1rem;
  color: $primary_color;
  text-decoration: underline;
  border: none;
  -webkit-tap-highlight-color: transparent;
  transition: .2s ease-in-out;
}

//p a:hover {
//  font-size: 1rem;
//  color: $secondary_color;
//  text-decoration: underline;
//  border: none;
//  -webkit-tap-highlight-color: transparent;
//}

a:focus, a:active {
  outline: none;
  box-shadow: none;
}

.container {

  width: calc(100vw - 32px);
  margin: auto;

  @media (min-width: 600px) {
    width: calc(100vw - 64px);
    margin: auto;
  }

  @media (min-width: 1024px) {
    width: 80%;
    margin: auto;
  }

  @media (min-width: 1285px) {
    width: 1280px;
    margin: auto;
  }
}

.container-small {

  width: calc(100vw - 32px);
  margin: auto;

  @media (min-width: 600px) {
    width: calc(100vw - 64px);
    margin: auto;
  }

  @media (min-width: 1024px) {
    width: 80%;
    margin: auto;
  }

  @media (min-width: 1285px) {
    width: 960px;
    margin: auto;
  }
}

.divider {
  width: 100%;
  margin: 16px auto;
  background: $primary_very_light_color;
  height: 1px;
}



