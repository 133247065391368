@import "variables";
@import "colors";
@import "healpers";

//@media (min-width: 600px) {}
//@media (min-width: 1024px) {}
//@media (min-width: 1280px) {}

.cta {
  //border: .1em solid #fefefe;
  @extend .box-shadow;
  //box-shadow: 0 40px 40px -25px rgba(0,0,0,.3);
  border-radius:20px;
  //margin-bottom: 64px;
  text-align: center;
  padding:32px;
  //margin-top: 32px;
  background: linear-gradient( #FFFFFF 10% , $very_light_grey 90%);
  //background: $primary_very_light_color;
}
.cta p{
  font-size: 1.2rem;
  text-align:center;
}

.cta-full {

  border-radius: 20px;
  background: $secondary_color;
  padding: 32px;
  margin-top: 64px;
  margin-bottom: 64px;
}
.cta-full h2, .cta-full ul {
  color: $light_color
}

.cta-full ul li {
  padding-top: 8px;
  padding-bottom: 8px;
}

.cta-full h2 {
  margin-top:0;
  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  @media (min-width: 1280px) {
    font-size: 3rem;
  }
}