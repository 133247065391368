
@import "variables";

textarea {
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 150px;
  padding: 12px 16px;
  box-sizing: border-box;
  border: 1px solid $primary_very_light_color;
  border-radius: 10px;
  background-color: $light_color;
  resize: none;
  outline: none;
  font-size: 16px;
}

select {
  font-family: "Roboto", sans-serif;
  width: 100%;
  font-size: 16px;
  padding: 14px 16px;
  border: 1px solid $primary_very_light_color;
  border-radius: 10px;
  background-color: $light_color;
  outline: none;
}

select + label {
  left: 16px;
  position: absolute;
  transform: translateY(-24px) !important;
  font-size: 16px;
  background: $light_color;
  color: $primary_very_light_color;
}

.input-field {
  position: relative;
  flex: 1 1 calc(50% - 24px);
}

.input-field input[type="email"],
.input-field input[type="password"],
.input-field input[type="text"] {
  font-family: "Roboto", sans-serif;
  border: 1px solid $primary_very_light_color;
  padding: 10px 16px;
  font-size: 16px;
  outline: none;
  line-height: 28px;
  border-radius: 10px;
  width: calc(100% - 34px);
  transition: all .3s ease-in-out .1s;
  background: $light_color;
}

.input-field input[type="text"] + label,
.input-field input[type="email"] + label,
textarea + label {
  display: inline-block;
  padding: 0 8px;
  font-size: 16px;
  position: absolute;
  left: 16px;
  top: 15px;
  text-transform: capitalize;
  color: #656565;
  transition: all .175s ease-in-out;
}

.input-field input[type="text"]:focus,
.input-field input[type="email"]:focus,
textarea:focus
{
  box-shadow: 0 0 10px -2px $primary_very_light_color;
}

.input-field input[type="text"]:focus + label,
.input-field input[type="text"]:not(:placeholder-shown) + label,
.input-field input[type="text"]:-webkit-autofill + label,
.input-field input[type="email"]:focus + label,
.input-field input[type="email"]:not(:placeholder-shown) + label,
.input-field input[type="email"]:-webkit-autofill + label,
textarea:focus + label,
textarea:not(:placeholder-shown) + label,
textarea:-webkit-autofill + label {
  transform: translateY(-24px) !important;
  font-size: 13px;
  background: $primary_light_color;
  border-radius: 5px;
  color: $light_color;
}


