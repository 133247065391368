@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100..900;1,100..900&display=swap');


// Primary colors
$primary_very_light_color: #9FB6DD;
$primary_light_color: #274060;
$primary_color: #0A2540;
$primary_dark_color: #081A2D;

// Secondary colors
$secondary_very_light_color:  #C280B7;
$secondary_light_color: #B061A8;
$secondary_color: #8F3985;
$secondary_dark_color: #6A2964;

// Accent colors
$accent_color: #FF6B35;
$accent_light_color: #F45B69;
$accent_dark_color: #F45B69;

// Base colors
$dark_color: #333333;
$soft_black : #1C1C1C;
$dark_violet : #4B235A;

$light_color: #F5F5F5;
$very_light_grey: #ECEFF1;
$pale_blue: #E0F7FA;
$light_beige : #FAF3DD;
$pale_lavande : #F3E5F5;

$error_color: #FF6B35;
$success_color: #7FFF00;
$info_color: #00FFD1;


@font-face {
  font-family: 'Amalfi_Coast';
  src: url('../../fonts/Amalfi_Coast.ttf');
}