@import "contact_form";
@import "../colors";
//@media (min-width: 600px) {}
//@media (min-width: 1024px) {}
//@media (min-width: 1280px) {}

#contact-form-wrapper, #contact-infos-wrapper, #contact-infos-wrapper .flex-box .flex-col, #contact-intro .flex-box .flex-col {
  flex: 1 1 calc(100%);
}

#contact-intro .flex-box .flex-col img {
  display: block;
  width: 70%;
  margin: auto;
  border-radius: 20px;
  box-shadow: 0 20px 40px -20px $dark_color;
}

#contact-intro .flex-box {
  align-items: center;
}

#contact-intro .top-subtitle {
  text-align: center;
}

#contact-intro h2 {
  text-align: center;
  margin-top: 0;
}

#contact-infos-wrapper {
  margin-top: 64px;
}

#contact-form-wrapper, #contact-intro .flex-box .flex-col {
  @media (min-width: 600px) {
    flex: 1 1 calc(50% - 24px);
  }
  @media (min-width: 1024px) {

  }
  @media (min-width: 1280px) {

  }

}

#contact-infos-wrapper .flex-box .flex-col {
  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  @media (min-width: 1280px) {
    flex: 1 1 calc(33% - 24px);
  }

}

