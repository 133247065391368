@import 'variables';
@import 'flex_box';
@import 'healpers';
//@media (min-width: 600px) {}
//@media (min-width: 1024px) {}
//@media (min-width: 1280px) {}

.flex-cards {

  //@extend .flex-box;

  margin-top: 64px;
  margin-bottom: 64px;
  //background: #FFFFFF;


  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}

  h2 {
    //@media (min-width: 600px) {}
    @media (min-width: 1024px) {
      text-align: center;
    }
    @media (min-width: 1280px) {

    }
  }

  p {

    //@media (min-width: 600px) {}
    @media (min-width: 1024px) {
      text-align: center;
    }
    @media (min-width: 1280px) {

    }
  }

  .card {

    @extend .flex-col;
    @extend .flex-box;
    @extend .card-shadow;

    gap: 0;
    background: rgba(250, 250, 252, 1);
    align-items: stretch;
    //border: .1em solid $primary_light_color;
    border-radius: 20px;
    overflow: hidden;
    //box-shadow: 0 10px 15px -10px $primary_light_color;

    @media (min-width: 600px) {
      flex: 1 1 100% !important;
    }
    @media (min-width: 1024px) {
      flex: 1 1 calc(50% - $flex_gap_laptop) !important;
    }
    @media (min-width: 1280px) {
      flex: 1 1 calc(50% - $flex_gap_desktop) !important;
    }

    .icon-box {

      @extend .flex-col;
      display: flex;
      background: $primary_light_color;
      justify-content: center !important; /* Centrage horizontal (facultatif si besoin) */
      align-items: center !important; /* Centrage vertical du texte */
      padding: 16px 0;

      @media (min-width: 600px) {
        flex: 1 1 calc(20% - $flex_gap_tab) !important;
        //padding-left : 16px;
        padding: 0;
      }
      @media (min-width: 1024px) {

      }

      @media (min-width: 1280px) {
        flex: 1 1 calc(80px - $flex_gap_desktop) !important;
        //padding-left : 16px;
        padding: 0;
      }

      .icon {
        color: #FFFFFF;
        font-size: 1.8rem;
        @media (min-width: 600px) {
          font-size: 2.25rem;
        }
        //@media (min-width: 1024px) {}
        //@media (min-width: 1280px) {}
      }
    }

    .description {
      @extend .flex-col;

      padding: 8px;

      @media (min-width: 600px) {
        flex: 1 1 calc(80% - $flex_gap_tab) !important;
        padding: 16px;
      }
      @media (min-width: 1024px) {
        flex: 1 1 calc(70% - $flex_gap_laptop);
        padding: 16px;
      }

      @media (min-width: 1280px) {
        flex: 1 1 calc(80% - $flex_gap_desktop);
        padding: 16px;
      }

      h3 {
        text-align: center;
        @media (min-width: 600px) {
          text-align: left;
        }
        @media (min-width: 1024px) {
        }
        @media (min-width: 1280px) {

        }
      }

      p {
        text-align: justify;
        //@media (min-width: 600px) {}
        //@media (min-width: 1024px) {}
        //@media (min-width: 1280px) {}
        .sn-link {
          font-size: 2rem;
          margin-right: 8px;
        }
        .sn-link:hover {
          color: $secondary_color;
          font-size: 2rem;
          margin-right: 8px;
        }
        a {
          font-size: 1.1rem;
          text-decoration: none !important;
          font-weight: 600 !important;
          color: $primary_light_color
        }
      }

    }
  }

}