@import 'variables';
@import 'flex_box';
@import 'healpers';

//@media (min-width: 600px) {}
//@media (min-width: 1024px) {}
//@media (min-width: 1280px) {}

.flex-list {

  @extend .flex-box;
  align-items: center !important;

  .image-box {

    @extend .flex-col;
    display: flex;
    justify-content: center !important; /* Centrage horizontal (facultatif si besoin) */
    align-items: center !important; /* Centrage vertical du texte */
    text-align: center;
    padding-bottom: 32px;

    picture img {

      @extend .border-radius;
      position: relative;
      box-shadow: 0 20px 40px -20px $dark_color;
      width: 80%;

    }

  }

  //.image-box:before {
  //  @extend .border-radius;
  //
  //  position: absolute;
  //  content: '';
  //  background: linear-gradient(45deg, $primary_color, $secondary_color);
  //  left: 0;
  //  top: -32px;
  //  width: 196px;
  //  height: 128px;
  //  opacity: .9;
  //  z-index: -1;
  //
  //  @media (min-width: 600px) {
  //    top: 156px;
  //  }
  //  //@media (min-width: 1024px) {}
  //  //@media (min-width: 1280px) {}
  //
  //}
  //
  //.image-box:after {
  //  @extend .border-radius;
  //  position: absolute;
  //  content: '';
  //  background: radial-gradient(rgba(255, 255, 255, .1) 20%, rgba(255, 255, 255, .8));
  //  right: 56px;
  //  bottom: 0;
  //  width: 128px;
  //  height: 256px;
  //  z-index: 1;
  //  @media (min-width: 600px) {
  //    bottom: 156px;
  //  }
  //  //@media (min-width: 1024px) {}
  //  //@media (min-width: 1280px) {}
  //}

  .list-box {

    @extend .flex-col;

    .list {

      .item {

        @extend .flex-box;
        align-items: center !important;

        .icon-box {

          @extend .flex-col;
          flex: 1 1 calc(30% - $flex_gap_mobile) !important;
          //@media (min-width: 600px) {}
          //@media (min-width: 1024px) {}
          @media (min-width: 1280px) {
            flex: 1 1 calc(64px) !important;
          }

          .filled-icon {
            display: inline-block;
            width: 64px;
            height: 64px;
            line-height: 79px;
            text-align: center;
            background: $secondary_color;
            border-radius: 10px;

            .icon {
              color: #FFFFFF;
              line-height: 64px;
              font-size: 36px;
            }

          }


        }

        .description {
          flex: 1 1 calc(70% - $flex_gap_mobile) !important;
          @extend .flex-col;
          //@media (min-width: 600px) {}
          //@media (min-width: 1024px) {}
          @media (min-width: 1280px) {
            flex: 1 1 calc(100% - $flex_gap_desktop - 64px) !important;
          }
        }
      }
    }
  }

  h2 {

  }

  p {

  }
}