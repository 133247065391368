
@import 'variables';

.primary-light {
  background: $primary_light_color !important;
}

.primary {
  background: $primary_color !important;
}

.primary-dark {
  background: $primary_dark_color !important;
}

.primary-light-text {
  color: $primary_light_color !important;
}

.primary-text {
  color: $primary_color !important;
}

.primary-dark-text {
  color: $primary_dark_color !important;
}


.secondary-light {
  background: $secondary_light_color !important;
}

.secondary {
  background: $secondary_color !important;
}

.secondary-dark {
  background: $secondary_dark_color !important;
}

.secondary-light-text {
  color: $secondary_light_color !important;
}

.secondary-text {
  color: $secondary_color !important;
}

.secondary-dark-text {
  color: $secondary_dark_color !important;
}

.accent-text-light {
}

.accent-text {
  color: $accent_color !important;
}

.accent-text-dark {
}

.accent-light {
}

.accent {
  background: $accent_color !important;
}

.accent-dark {
}

.dark {
  background: $dark_color !important;
}

.dark-text {
  color: $dark_color !important;
}

.light-text {
  color: $light_color !important;
}
.light {
  background: $light_color !important;
}


.error {
  background: $error_color !important;
}

.error-text {
  color: $error_color !important;
}

.info {
  background: $info_color !important;
}
.info-text {
  color: $info_color !important;
}

.success-text {
  color: $success_color !important;
}
.success {
  background: $success_color !important;
}

.transparent {
  background-color: transparent !important;
}

.transparent-text {
  color: transparent !important;
}

.gradient-primary {
  background: linear-gradient(45deg,
          $primary_dark_color 0%,
          $primary_color 40%,
          $primary_light_color 70%,
          $primary_color 85%,
          $primary_dark_color 100%
  );
}

.gradient-secondary {
  background: linear-gradient(45deg,
          $secondary_dark_color 0%,
          $secondary_color 40%,
          $secondary_light_color 70%,
          $secondary_color 85%,
          $secondary_dark_color 100%
  );
}

.gradient-45deg-primary {
  background: linear-gradient(45deg,$primary_color, $primary_dark_color);
}
.gradient-45deg-secondary {
  background: linear-gradient(45deg,$secondary_dark_color, $secondary_color);
}


.gradient-45deg-accent{
  background: linear-gradient(45deg,$accent_light_color, $accent_color);
}

.gradient-45deg-light {
  background: linear-gradient(45deg,$primary_light_color 30%, $secondary_light_color);
}
.gradient-45deg-dark {
  background: linear-gradient(45deg,$primary_dark_color, $secondary_dark_color);
}


.gradient-45deg {
  background: linear-gradient(45deg,$primary_color 30%, $secondary_color);
}
.gradient-45deg-reverse {
  background: linear-gradient(225deg,$primary_color 60%, $secondary_color);
}

//.gradient-45deg-lo-animate {
//  background: linear-gradient(-45deg, rgb(149, 62, 157), rgb(84, 125, 201), rgb(149, 62, 157), rgb(84, 125, 201));
//  background-size: 400% 400%;
//  -webkit-animation: Gradient 90s ease infinite;
//  -moz-animation: Gradient 90s ease infinite;
//  animation: Gradient 90s ease infinite;
//}

.gradient-text {
  background: linear-gradient(45deg, $primary_color 30%,$secondary_color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


