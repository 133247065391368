@import "../variables";

//@media (min-width: 600px) {}
//@media (min-width: 1024px) {}
//@media (min-width: 1280px) {}

#top-bar {
  z-index: 3;
  position: fixed;
  height: 56px;
  width: 100%;
  background-color: transparent;
  box-shadow: none;
  transition: background-color .3s, box-shadow .45s;

  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  @media (min-width: 1280px) {
    z-index: 3;
    position: fixed;
    height: 72px;
    width: 100%;
    border-bottom: .01em solid #FFFFFF33;
    background: transparent;
    box-shadow: none;
  }
}

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
#top-bar.sticky {
  z-index: 3;
  position: fixed;
  height: 56px;
  width: 100%;
  background-color: white;
  box-shadow: 0 5px 30px rgba(0, 0, 0, .5);
  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  @media (min-width: 1280px) {
    z-index: 3;
    position: fixed;
    height: 72px;
    width: 100%;
    background-color: white;
    box-shadow: 0 5px 30px rgba(0, 0, 0, .5);
  }
}

#top-bar-logo {
  height: 40px;
  //margin-left: 16px;
  margin-top: 8px;
}

#top-bar-logo-img {
  height: 40px;
  margin-top: 8px;
  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  @media (min-width: 1280px) {
    height: 40px;
    margin-top: 16px;
  }
}

#top-bar-menu-icon {
  position: fixed;
  z-index: 5;
  display: inline-block;
  cursor: pointer;
  right: 16px;
  top: 12px;
}

.colored {
  //background: linear-gradient(45deg, $primary_color, $secondary_color) !important;
  background: $primary_color !important;
}

#bar1, #bar3 {
  width: 35px;
  height: 4px;
  background: white;
  margin: 5px 0;
  transition: 0.5s;
  border-radius: .3em;
}

#bar2 {
  margin: auto;
  border-radius: .3em;
  width: 25px;
  height: 4px;
  background: white;
  transition: 0.4s;
}

#bar1.change {
  transform: translate(0, 9px) rotate(-45deg);
  background: white;
}

#bar2.change {
  opacity: 0;
}

#bar3.change {
  transform: translate(0, -9px) rotate(45deg);
  background: white;
}

#sidenav-wrapper {
  position: fixed;
  z-index: 99;
  width: 65%;
  box-shadow: none;
  background: $light_color;
  border-radius: 20px;
  top: 72px;
  left: -100vw;
  overflow: hidden;
  transition: all .5s ease-in-out .1s;

  @media (min-width: 600px) {
    position: fixed;
    z-index: 4;
    width: 40%;
    box-shadow: none;
    background: $light_color;
    border-radius: 20px;
    top: 72px;
    left: -100vw;
    overflow: hidden;
    transition: all .5s;
  }
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}

#sidenav-wrapper #sidenav ul {
  list-style: none;
  padding-left: 0;
}

#sidenav-wrapper #sidenav {
  margin-top: 16px;
}


.sidenav-item {
  display: block;
  padding: 8px 16px;
  font-weight: 400;
  text-decoration: none;
  color: $secondary_color;
  margin-bottom: 16px;
  font-size: 1.1rem;
  position: relative;
  transition: all .3s ease-out;
}

.sidenav-item.active, .sidenav-item:hover {
  display: block;
  padding: 8px 16px;
  font-weight: 400;
  text-decoration: none;
  color: $primary_color;
  font-size: 1.1rem;
  position: relative;
}

.sidenav-item.active {
  display: block;
  padding: 8px 16px;
  font-weight: 600;
  text-decoration: none;
  color: $primary_color;
  font-size: 1.1rem;
  position: relative;
  border-top: 0.01em solid $primary_light_color;
}


.subnav {
  max-height: 0; /* Cache les sous-menus par défaut en définissant une hauteur maximale de 0 */
  overflow: hidden; /* Cache tout contenu débordant */
  transition: max-height 0.5s ease-out; /* Ajoute une transition fluide pour l'agrandissement */
  padding-left: 34px;

}

.subnav.show {
  max-height: 500px; /* Définissez une hauteur maximale suffisante pour vos sous-menus */
  margin-top: 8px;
  margin-bottom: 24px;
  border-bottom: 0.01em solid $primary_light_color;
  border-top: 0.01em solid $primary_light_color;

}

.subnav li {
  padding-top: 8px;
  padding-bottom: 8px;
}

.subnav li a {
  text-decoration: none;
}

.accordion-icon {
  transition: transform 0.5s ease;
  display: inline-block;
  text-align: right;
  float: right;
}

.accordion-trigger.active .accordion-icon {
  transform: rotate(90deg); /* Pivote l'icône de 90 degrés lorsqu'il est actif */
}


.accordion-item ul.subnav {
  margin-left: 32px;
}

#sidenav-logo {
  margin-left: 16px;
}

#sidenav-logo-img {
  width: 60%;
}



#top-bar-menu-icon, #sidenav-wrapper {
  //@media (min-width: 600px) {}
  @media (min-width: 1024px) {

  }
  @media (min-width: 1280px) {
    display: none;
  }

}
