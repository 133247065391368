@import '../variables';

//@media (min-width: 600px) {}
//@media (min-width: 1024px) {}
//@media (min-width: 1280px) {}

#about {
  position: relative;
}

#about .flex-box {
  position: relative;
  align-items: center;

}

#about .flex-box .flex-col {
  flex : 1 1 100%;
  position: relative;
  padding-top:32px;
  padding-bottom:32px;
  //@media (min-width: 600px) {}
  @media (min-width: 1024px) {
    position: relative;
    flex : 1 1 calc(50% - 8px);
  }
  //@media (min-width: 1280px) {}

}

#about .flex-box .flex-col .image {
  width:100%;
  margin:auto;
  position: relative;
  padding-top:32px;
  padding-bottom:32px;
  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  @media (min-width: 1280px) {
    width:80%;
    margin:auto;
    position: relative;
    padding-top:32px;
    padding-bottom:32px;
  }
}

#about .flex-box .flex-col .image img {
  display: block;
  width:80%;
  margin:auto;
  border-radius: 20px;
  box-shadow: 0 20px 40px -20px $dark_color;
}

#about .flex-box .flex-col .image:before {
  position: absolute;
  content: '';
  background: linear-gradient(45deg, $primary_color, $secondary_color);
  left: 0;
  top: 0;
  border-radius: 35px;
  width: 128px;
  height: 128px;
  opacity: .9;
  z-index: -1;

  @media (min-width: 600px) {
    position: absolute;
    content: '';
    background: linear-gradient(45deg, $primary_color, $secondary_color);
    left: -20px;
    top: -25px;
    border-radius: 35px;
    width: 180px;
    height: 128px;
    opacity: 1;
    z-index: -1;
  }
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}

#about .flex-box .flex-col .image:after {
  position: absolute;
  content: '';
  background: linear-gradient(45deg, $primary_color, $secondary_color);
  //background: $primary_color;
  right: 0;
  bottom: -10px;
  border-radius: 35px;
  width: 30%;
  height: 160px;
  opacity: .5;
  z-index: 1;
  //border: 2px solid $primary_color;

  @media (min-width: 600px) {
    position: absolute;
    content: '';
    background: linear-gradient(45deg, $primary_color, $secondary_color);
    //background: $primary_color;
    right: 0;
    bottom: -16px;
    border-radius: 35px;
    width: 40%;
    height: 160px;
    opacity: .5;
    z-index: 1;
    //border: 2px solid $primary_color;
  }
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}



#page-about-signature {
  font-family: Amalfi_Coast, arial, sans-serif;
  font-size: 24px;
}