@import 'variables';
@import 'flex_box';

//@media (min-width: 600px) {}
//@media (min-width: 1024px) {}
//@media (min-width: 1280px) {}

.flex-block {

  @extend .flex-box;
  align-items: center !important;
  //padding-bottom: 64px;
  @media (min-width: 600px) {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  @media (min-width: 1024px) {}
  @media (min-width: 1280px) {

  }

  .image-box {

    @extend .flex-col;

    @media (min-width: 600px) {
      flex: 1 1 calc(30% - $flex_gap_tab) !important;
    }

    @media (min-width: 1024px) {
      flex: 1 1 calc(40% - $flex_gap_laptop) !important;
    }

    @media (min-width: 1280px) {
      flex: 1 1 calc(40% - $flex_gap_desktop) !important;
    }

    picture img {

      position: relative;
      display: block;
      margin: 24px auto;
      width: 75%;

      @extend .border-radius;
      box-shadow: 0 20px 40px -20px $dark_color;

      @media (min-width: 600px) {
        width: 200%;
        right:192px;
        z-index: -1;
      }

      @media (min-width: 1024px) {
        top: 16px;
        width: 120%;
        left: -40%;
      }
      @media (min-width: 1280px) {
        top: 32px;
        width: 100%;
        left: -30%;
      }

    }

  }

  .image-box:before {

    @extend .border-radius;

    position: absolute;
    content: '';
    background: linear-gradient(45deg, $primary_color, $secondary_color);
    left: 0;
    top: 0;
    width: 128px;
    height: 128px;
    opacity: .9;
    z-index: -1;

    @media (min-width: 600px) {
      left: auto;
      right:0 !important;
    }
    @media (min-width: 1024px) {
      top: -32px;
      width: 312px;
      height: 164px;
    }
    @media (min-width: 1280px) {
      top:0;
      height: 192px;

    }

  }

  .image-box:after {

    @extend .border-radius;
    position: absolute;
    content: '';
    background: radial-gradient(rgba(255, 255, 255, .1) 20%, rgba(255, 255, 255, .8));
    right: 0;
    bottom: 0;
    width: 128px;
    height: 128px;
    z-index: 1;

    @media (min-width: 600px) {

    }

    @media (min-width: 1024px) {
      right:120px;
      width: 144px;
      bottom: -40px;
    }
    @media (min-width: 1280px) {
      width: 152px;
      height: 152px;
      right:216px;
    }
  }

  .text-box {

    @extend .flex-col;

    @media (min-width: 600px) {
      flex: 1 1 calc(70% - $flex_gap_tab) !important;
    }
    @media (min-width: 1024px) {
      flex: 1 1 calc(60% - $flex_gap_laptop) !important;
    }
    @media (min-width: 1280px) {
      flex: 1 1 calc(60% - $flex_gap_desktop) !important;
    }

    .text {

      @extend .border-radius;
      @extend .box-shadow;
      background: white;
      padding: 32px;
      ul {
        list-style-type: none; /* Supprime les puces par défaut */
        padding-left: 0; /* Supprime le retrait par défaut des listes */
      }

      ul li {
        position: relative; /* Nécessaire pour positionner l'icône */
        padding-left: 32px; /* Espace pour l'icône */
        padding-bottom: 16px;
        font-size: 1.2rem;
      }

      ul li::before {
        content: "\f00c"; /* Code unicode de l'icône Font Awesome (ici, "check") */
        font-family: "Font Awesome 6 Pro", serif; /* Police utilisée */
        font-weight: 900; /* Poids pour afficher les icônes solides */
        position: absolute; /* Positionnement de l'icône */
        left: 0; /* Place l'icône à gauche du texte */
        top: 0; /* Alignement vertical */
        font-size: 1.5rem; /* Taille de l'icône */
        color: $secondary_color; /* Couleur de l'icône */
      }

      //@media (min-width: 600px) {}
      //@media (min-width: 1024px) {}
      @media (min-width: 1280px) {

      }

      h2 {
      }

      p {
      }
    }

    .actions {
      position:relative;
      top: -20px;
      text-align: center;
    }
  }

}

.flex-block.image-first {


  @media (min-width: 600px) {}
  @media (min-width: 1024px) {}
  @media (min-width: 1280px) {}

  .image-box {

    @media (min-width: 600px) {

    }

    @media (min-width: 1024px) {

    }

    @media (min-width: 1280px) {

    }

    picture img {

      @media (min-width: 600px) {
        left: 32px;

      }

      @media (min-width: 1024px) {

      }
      @media (min-width: 1280px) {
        left: 25%;
      }

    }

  }

  .image-box:before {

    @media (min-width: 600px) {
      left: 0;
      right:auto;
      top: 0;
    }
    @media (min-width: 1024px) {
      top: -32px;
    }
    @media (min-width: 1280px) {
      top: 0;
    }

  }

  .image-box:after {

    @media (min-width: 600px) {
      right: auto;
      left:0;
    }

    @media (min-width: 1024px) {
      right: auto;
      left:64px;
    }
    @media (min-width: 1280px) {
      left:192px;
    }
  }

  .text-box {



    @media (min-width: 600px) {

    }
    @media (min-width: 1024px) {

    }
    @media (min-width: 1280px) {

    }

    .text {

      //@media (min-width: 600px) {}
      //@media (min-width: 1024px) {}
      //@media (min-width: 1280px) {}

      h2 {
      }

      p {
      }
    }

    .actions {

    }
  }

}