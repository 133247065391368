@import "variables";

/* Buttons */

.btn-logic {
  cursor: pointer;
  display: inline-block;
  border: none;
  background: linear-gradient(45deg, $primary_light_color 0%, $secondary_color 65%);
  //text-transform: capitalize;
  position: relative;
  text-align: center;
  letter-spacing: .5px;
  padding: 0 16px;
  text-decoration: none;
  font-size: 18px;
  height: 42px;
  line-height: 42px;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  color: white;
  transition: background-position .75s, border-radius .9s;
  background-size: 200% 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  font-family: "Saira", sans-serif;
}

.btn-logic:hover {
  @extend .btn-logic;
  background-position: 100% 0;
}

.btn-large-logic {
  cursor: pointer;
  display: inline-block;
  border: none;
  background: linear-gradient(45deg, $primary_color 0%, $secondary_color 65%, $primary_color 110%);
  text-transform: uppercase;
  position: relative;
  text-align: center;
  letter-spacing: .5px;
  padding: 0 32px;
  font-size: 20px;
  font-weight: normal;
  text-decoration: none;
  height: 64px;
  line-height: 64px;
  border-radius: 16px;
  color: white;
  background-size: 200% 200%; /* Permet de gérer l'animation du dégradé */
  transition: background-position 0.75s ease, background-size 0.75s ease;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  font-family: "Saira", sans-serif;
}

.btn-large-logic:hover {
  @extend .btn-large-logic;
  background-position: 100% 0; /* Fait glisser le dégradé */
}




.btn {
  cursor: pointer;
  display: inline-block;
  border: none;
  //text-transform: capitalize;
  position: relative;
  text-align: center;
  letter-spacing: .5px;
  padding: 0 16px;
  text-decoration: none;
  height: 36px;
  line-height: 36px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  //box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  //-moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  //-webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  color: white;
}

.btn-large {
  cursor: pointer;
  display: inline-block;
  border: none;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  letter-spacing: .5px;
  padding: 0 16px;
  text-decoration: none;
  height: 52px;
  line-height: 52px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  box-shadow: 0 0 40px rgba(0, 0, 0, .2);
  -moz-box-shadow: 0 0 40px rgba(0, 0, 0, .2);
  -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, .2);
}

.btn.outline {
  border: 1px solid #333333;
  background: #ffffff;
  color: #333333;
  height: 34px;
  line-height: 34px;
}

.btn-large.outline {
  border: 2px solid #333333;
  background: transparent !important;
  color: #333333;
  height: 52px;
  line-height: 52px;
}

.btn.primary, .btn-large.primary {
  background: $primary_color;
  color: $light_color;
}

.btn.secondary, .btn-large.secondary {
  background: $secondary_color;
  color: $light_color;
}

.btn.accent, .btn-large.accent {
  background: $accent_color;
  color: $light_color;
}

.btn.primary.outline {
  border: 1px solid $primary_color;
  background: transparent !important;
  color: $primary_color;
}

.btn-large.primary.outline {
  border: 2px solid $primary_color;
  background: transparent !important;
  color: $primary_color;
}


.btn.secondary.outline {
  border: 1px solid $secondary_color;
  background: transparent;
  color: $secondary_color;
}

.btn-large.secondary.outline {
  border: 2px solid $secondary_color;
  background: transparent;
  color: $secondary_color;
}

.btn.accent.outline {
  border: 1px solid $accent_color;
  background: transparent;
  color: $accent_color;
}

.btn-large.accent.outline {
  border: 2px solid $accent_color;
  background: transparent;
  color: $accent_color;
}

.btn.light.outline {
  border: 1px solid $light_color;
  background: transparent;
  color: $light_color;
}

.btn-large.light.outline {
  border: 2px solid $light_color;
  background: transparent;
  color: $light_color;
}
