@import '../variables';
@import '../healpers';

//@media (min-width: 600px) {}
//@media (min-width: 1024px) {}
//@media (min-width: 1280px) {}

#services {
  position: relative;
  padding-top: 64px;
  padding-bottom: 64px;
}

#services .top-subtitle {

}

#services .flex-box {
  gap: 24px;
}

#services .top-subtitle {
  @media (min-width: 600px) {
    text-align: center;
  }
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}


#services h2 {
  margin-top: 0;

  @media (min-width: 600px) {
    text-align: center;
    margin-top: 0;
  }
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}

}

.service {
  @extend .card-shadow;
  background: $light_color;
  position: relative;
  //border: .01em solid $primary_very_light_color;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  flex: 1 1 100%;
  transition: all .55s ease .0s;
  top: 0;

  @media (min-width: 600px) {
    flex: 1 1 calc(50% - 24px);
  }
  @media (min-width: 1024px) {
    flex: 1 1 calc(50% - 24px);
  }
  @media (min-width: 1280px) {
    flex: 1 1 calc(25% - 24px);
  }

}

.service:hover {
  box-shadow: 0 25px 30px -8px $primary_very_light_color;
  //border: .01em solid $primary_light_color;
  top: -8px;
  //position:absolute;
}

.service:hover .service-icon {
  color: $primary_light_color;
}


.service-icon {
  text-align: center;
  font-size: 72px;
  color: $primary_very_light_color;
  padding: 16px;
  transition: all .5s ease 0s;
}

.service-action {
  position: absolute;
  width: calc(100% - 32px);
  bottom: 0;
  padding: 16px;
  text-align: right;
}

.service-action a {
  text-decoration: none;
  padding: 0 .25rem;
  box-shadow: inset 0 0 0 0 $secondary_color;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.service-action a:hover {
  color: $light_color;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
  box-shadow: inset 200px 0 0 0 $secondary_color;
}

.service h3 {
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;

  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}

  @media (min-width: 1280px) {
    text-align: center;
    padding-left: 32px;
    padding-right: 32px;
  }
}

.service p {
  padding: 16px 16px 56px;

  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}

  @media (min-width: 1280px) {
    padding: 32px;
  }

}

#home-services-wave {
  position: relative;
  width: 100%;
  height: 320px;
}