@import "variables";

/* Style the list */
ul.breadcrumb {
  padding: 4px 16px;
  list-style: none;
  background: linear-gradient($light_color , #FFFFFF 60% );
  //border: .01em solid $primary_light_color;
  border-radius: 16px;
  box-shadow: 0 0 6px -2px rgba(0,0,0,.1);
}

/* Display list items side by side */
ul.breadcrumb li {
  color: $primary_light_color;
  display: inline;
  font-size: 18px;
  line-height: 28px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li+li:before {
  font-family: "Font Awesome 6 Pro", serif;
  padding: 8px;
  color: $primary_light_color;
  content: "\f105";
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
  color: $primary_color;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: $primary_dark_color;
  //text-decoration: underline;
}