@import "../variables";

//@media (min-width: 600px) {}
//@media (min-width: 1024px) {}
//@media (min-width: 1280px) {}

#footer {
  position: relative;
  background: $primary_color;
  padding-top:16px;
  padding-bottom:16px;
}

#footer h3 {
  font-size: 16px;
}

#footer-description {
  font-style: italic;
  font-size: 14px;
}

#footer ul {
  list-style: none;
  padding: 0;
}

#footer ul li a {
  color: $light_color;
  text-decoration: none;
  padding: 0 .25rem;
  box-shadow: inset 0 0 0 0 $secondary_dark_color;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
  font-size: 16px;
}

#footer ul li a:hover {
  color: $light_color;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
  box-shadow: inset 200px 0 0 0 $secondary_dark_color;
}


#footer ul li  {
  padding-top: 4px;
  padding-bottom: 4px;
}

.footer-col {
  position: relative;
  flex: 1 1 100%;

  @media (min-width: 600px) {
    flex: 1 1 calc(2% - 24px);
  }
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}

.footer-col p {
  color: $light_color;
}

#footer-logo {
  max-width: 256px;

  @media (min-width: 600px) {
    max-width: 200px;
  }
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}

.sn-link {
  color: $light_color;
  font-size: 25px;
}

#footer-copyright {
  background: #333333;
  //position: absolute;
  bottom: 0;

  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  @media (min-width: 1280px) {
    text-align: center;
  }
}

#footer-legal {
  margin: 0;
}

#footer-legal, #footer-legal a {
  font-size : 12px;
  color: $light_color;
}


#footer-copyright p {
  text-align: center;
  color: $light_color;
  font-size: .75rem;
  margin:0;

  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  @media (min-width: 1280px) {
    display: inline-block;
    color: $light_color;
    font-size: .75rem;
    margin:0;
  }
}

#footer-copyright a {
  color: $light_color;
}