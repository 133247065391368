@import "../flex_box";

.form-box {
  display: grid;
  grid-template-columns: repeat(8, 1fr); /* 7 colonnes */
  column-gap: 4px; /* Espacement entre les éléments */
  @media (min-width: 600px) {
    column-gap: 16px; /* Espacement entre les éléments */
  }
  @media (min-width: 1024px) {

  }
  //@media (min-width: 1280px) {}
}

.gender {
  grid-column: 1 / 5;
  grid-row: 1;
  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}

.firstName {
  grid-column: 1 / 5;
  grid-row: 2;
  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}

.lastName {
  grid-column: 5 / 9;
  grid-row: 2;
  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}

.society {
  grid-column: 1 / 9;
  grid-row: 3;
  @media (min-width: 600px) {
    grid-column: 1 / 5;
    grid-row: 3;
  }
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}

.position {
  grid-column: 1 / 9;
  grid-row: 4;
  @media (min-width: 600px) {
    grid-column: 5 / 9;
    grid-row: 3;
  }
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}

.email {
  grid-column: 1 / 9;
  grid-row: 5;
  @media (min-width: 600px) {
    grid-row: 4;
  }
  @media (min-width: 1024px) {
    grid-column: 1 / 7;
  }
  //@media (min-width: 1280px) {}
}

.officePhone {
  grid-column: 1 / 5;
  grid-row: 6;
  @media (min-width: 600px) {
    grid-row: 5;
  }
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}

.mobilePhone {
  grid-column: 5 / 9;
  grid-row: 6;
  @media (min-width: 600px) {
    grid-row: 5;
  }
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}

.subject {
  grid-column: 1 / 9;
  grid-row: 7;
  //@media (min-width: 600px) {}
  @media (min-width: 1024px) {
    grid-column: 1 / 7;
  }
  //@media (min-width: 1280px) {}
}

.message {
  grid-column: 1 / 9;
  grid-row: 8;
  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}

.acceptance {
  grid-column: 1 / 9;
  grid-row: 9;
  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}

.submit {
  text-align: right;
  padding-top:32px;
  grid-column: 1 / 9;
  grid-row: 10;
  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}

///* Optionnel: ajout d'un espacement supplémentaire */
.input-field {
  margin: 10px 0;
  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  //@media (min-width: 1280px) {}
}