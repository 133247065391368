@import "variables";

.border-radius {
  border-radius: 24px;
}

.box-shadow {
  box-shadow: 0 40px 40px -25px $primary_very_light_color;
  //box-shadow: 0 40px 40px -25px rgba(0,0,0,.3);
}

.card-shadow {
  box-shadow: 0 5px 30px -8px  $primary_very_light_color;
}

.bold {
  font-weight: bold;
}