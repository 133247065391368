@import "../variables";
@import "../colors";

//@media (min-width: 600px) {}
//@media (min-width: 1024px) {}
//@media (min-width: 1280px) {}

#home-hero {
  position: relative;
  z-index:0;
  top: 0;
  width: 100vw;
  //height: calc(100vh - 56px);
  overflow: hidden;
  padding-top: 128px;
  padding-bottom: 24px;
  background: transparent;

  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}

  @media (min-width: 1280px) {
    position: relative;
    top: 0;
    width: 100vw;
    overflow: hidden;
    padding-top: 128px;
    padding-bottom: 128px;
  }
}

#home-hero p {
  position: relative;
  z-index:4;
  color: $light_color;
  font-size: 1.2rem;
  margin-top: 5%;
  margin-bottom: 5%;

  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}

  @media (min-width: 1280px) {
    color: $light_color;
    font-size: 1.2rem;
    padding: 0;
    margin-top: 5%;
    margin-bottom: 5%;
  }
}

#home-hero-image {
  position: absolute;
  z-index: -2;
  right: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
}


#home-hero-video {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
}


#home-hero-overlay {
  @extend .gradient-primary;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;


  opacity: .85;
}

#home-hero-actions {
  position: relative;
  z-index:4;
  margin: 32px 0;
}

#home-hero-actions a {
  margin-bottom: 16px;
}

#home-hero-wave {
  position: absolute;
  z-index: 3;
  width: 100%;
  bottom: -2px;
  background-color: transparent;
}

#home-hero-wave svg {
  display: block;
  width: 100%;
}

#name {
  position: relative;
  z-index:4;
  color: $primary_color;
  background: $light_color;
  display: inline;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 16px;

  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}

  @media (min-width: 1280px) {
    color: $primary_color;
    background: $light_color;
    display: inline;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 24px;
    margin-top: 64px;
  }

}