@import "../variables";
@import "../healpers";
@import "../flex_box";
//@media (min-width: 600px) {}
//@media (min-width: 1024px) {}
//@media (min-width: 1280px) {}

#home-strengths{
  background: #FFFFFF;
  margin: auto;
  padding-top: 64px;
  padding-bottom: 64px;
  position: relative;

  //@media (min-width: 600px) {}
  @media (min-width: 1024px) {
  }
  //@media (min-width: 1280px) {}
}

#home-strengths .top-subtitle {
  text-align: center;
}

#home-strengths h2 {
  margin-top:0;
  text-align: center;
  margin-bottom: 48px;
}

.strength {
  flex: 1 1 100%;
  text-align: center;
  @media (min-width: 600px) {
    flex: 1 1 calc(50% - $flex_gap_tab);
  }
  @media (min-width: 1024px) {
    flex: 1 1 calc(50% - $flex_gap_laptop);
  }
  @media (min-width: 1280px) {
    flex: 1 1 calc(25% - $flex_gap_desktop);
  }

}

.strength:not(:first-of-type) {
  border-top: 1px solid $primary_light_color;
  padding-top: 24px;
  @media (min-width: 600px) {
    border-top: none;
    padding-top: 0;
  }
  @media (min-width: 1024px) {
    border-top: none;
    padding-top: 0;
  }
  @media (min-width: 1280px) {
    border-top: none;
    padding-top: 0;
  }
}

.strength:nth-of-type(n+3) {
  @media (min-width: 600px) {
    border-top: 1px solid $primary_light_color;
    padding-top: 24px;
  }
  @media (min-width: 1024px) {

  }
  @media (min-width: 1280px) {
    border-top: none;
    padding-top: 0;
  }

}

.strength p {
  padding: 16px;
  margin: 0;
}


.strength-icon{
  margin:auto;
  width:96px;
  text-align: center;
  line-height:96px;
  height:96px;

  color: $light_color;
  font-size: 64px;
  padding:8px;
  border-radius: 50%;
  background: $primary_color;
}

.strength h3 {
  margin-top: 16px;
  font-size: 28px;
  text-align: center;
}