@import "../variables";

#faq {
  margin-top: 64px;
  margin-bottom: 32px;
}

#faq h2 {
  margin-bottom:0;
  text-align: center;
}

#faq .top-subtitle {
  text-align: center;
}
.faq-article {
  border: 0.01em solid $primary_very_light_color;
  border-radius: 20px;
  padding: 16px;
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;
  //box-shadow: 0 0 30px $primary_light_color;
  background: #FFFFFF;
}

.faq-question {
  cursor: pointer;
}

.faq-question h3 {
  color: $primary_color;
  font-size: 24px;
  //background: grey;
  width: calc(100% - 40px)
}

.faq-response {
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease; /* Ajout de la transition */
}

.faq-response p {
  font-size: 18px;
  padding: 8px;
  font-weight:500;
}


.faq-trigger {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  //background:red;
  text-align: center;
  position: absolute;
  top: 24px;
  right: 24px;
}

.faq-trigger-icon {
  font-size: 16px;
  transition: transform 0.3s ease; /* Ajout de la transition pour l'icône */
}