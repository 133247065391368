@import "../variables";
//@media (min-width: 600px) {}
//@media (min-width: 1024px) {}
//@media (min-width: 1280px) {}

#topnav {
  display: none;

  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}

}

@media (min-width: 1280px) {
  #topnav {
    display: block;
    width: 794px;
    float: right;
  }

  #topnav ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    justify-content: flex-end;
    list-style: none;
    line-height: 72px;
  }

  #topnav ul {
    display: flex;
  }

  #topnav ul li {
    text-align: center;
    padding: 0 16px;
    //flex: 1 1 calc(16% - 2px);
  }

  .topnav-link {
    color: #FFFFFF;
    text-decoration: none;
    font-weight: 400;
    font-size: 18px;
    text-transform: capitalize;
    font-family: "Saira", sans-serif;
  }
  .topnav-link.bordered {
    color: #FFFFFF;
    text-decoration: none;
    font-weight: 400;
    font-size: 18px;
    text-transform: capitalize;
    border: .01em solid #FFFFFF;
    padding: 8px 16px;
    border-radius: 10px;
  }


  .topnav-link.scrolled {
    color: $primary_color;
    text-decoration: none;
    font-weight: 400;
    font-size: 18px;
    text-transform: capitalize;
  }

  #topnav ul li .subnav {
    display: none;
  }
}
