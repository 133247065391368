@import "../variables";

//@media (min-width: 600px) {}
//@media (min-width: 1024px) {}
//@media (min-width: 1280px) {}

#page-hero {
  position: relative;
  top:0;
  width: 100vw;
  overflow: hidden;
  padding-top: 64px;
  padding-bottom: 48px;
  min-height:196px;
  background: url('../../../../public/images/video2.png') right center;
  background-size: cover;

  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  @media (min-width: 1280px) {
    //padding-bottom: 96px;
  }
}

#page-hero p {
  position: relative;
  z-index: 2;
  color: $light_color;
  font-size: 16px;
  font-weight: 300;
  margin-top: 5%;
  margin-bottom: 5%;

  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  @media (min-width: 1280px) {
    margin-top: 16px;
    padding-bottom: 64px;
    text-align: left;
    position: relative;
    color: $light_color;
    font-size: 1.15rem;
    font-weight: 300;
  }
}

#page-hero-overlay {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  //background: $primary_color;
  background: linear-gradient(45deg, $primary_color 20%, $primary_light_color 50%, $primary_color);
  opacity: .96;
  backdrop-filter: blur(2px) !important;

  //background:linear-gradient(-8deg,$primary_color 90%,$secondary_color);
  //background:$secondary_color;
  //backdrop-filter: blur(4px);
}

#page-hero-wave {
  position:absolute;
  z-index: 1;
  width:100%;
  bottom:-8px;
}

#page-hero-text {

  //@media (min-width: 600px) {}
  //@media (min-width: 1024px) {}
  @media (min-width: 1280px) {
    width: 60%;
  }

}