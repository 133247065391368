//@media (min-width: 600px) {}
//@media (min-width: 1024px) {}
//@media (min-width: 1280px) {}

// Variables
$flex_gap_mobile: 16px;
$flex_gap_tab: 16px;
$flex_gap_laptop: 32px;
$flex_gap_desktop: 32px;

.flex-box {

  position: relative;
  display: flex;
  flex-flow: row wrap;
  gap: $flex_gap_mobile;
  align-items: stretch;

  @media (min-width: 600px) {
    gap: $flex_gap_tab;
  }
  @media (min-width: 1024px) {
    gap: $flex_gap_laptop;
  }
  @media (min-width: 1280px) {
    gap: $flex_gap_desktop;
  }

  .flex-col {

    flex: 1 1 100%;
    position: relative;

    @media (min-width: 600px) {
      flex: 1 1 calc(50% - $flex_gap_tab);
    }
    @media (min-width: 1024px) {
      flex: 1 1 calc(50% - $flex_gap_laptop);
    }
    @media (min-width: 1280px) {
      flex: 1 1 calc(50% - $flex_gap_desktop);
    }
  }

}

